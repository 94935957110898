import { createId, createUid, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

const uid = createUid(new Date(), 'NEG')

export const getDefaultDeal = (data: Partial<Deal>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'inbound',
  priority: '2',
  stage: 'contact',
  status: 'pending',
  rating: '2',
  uid: uid,
  name: uid,
  contactIds: [],
  tags: [],
  files: [],
}, data)

export const optionsDeals = {
  type: [
    { value: 'inbound', label: 'Entrada', description: 'Negocios iniciados por el cliente, generalmente al contarnos para solicitar información o cotización.', icon: 'i-mdi-arrow-down-circle', color: 'green' },
    { value: 'outbound', label: 'Salida', description: 'Negocios iniciados por nosotros, generalmente a través de actividades de prospección o ventas activas.', icon: 'i-mdi-arrow-up-circle', color: 'orange' },
  ],
  stage: [
    { value: 'contact', label: 'Contacto', description: 'Se requiere establecer el primer contacto con el prospecto para iniciar la negociación.', icon: 'i-mdi-circle-slice-1', color: 'blue' },
    { value: 'qualification', label: 'Cualificación', description: 'Recopilación de información y evaluación del prospecto para avanzar hacia una oferta.', icon: 'i-mdi-circle-slice-3', color: 'blue' },
    { value: 'quote', label: 'Cotización', description: 'El negocio está en la etapa de cotización, y se está esperando la respuesta del cliente.', icon: 'i-mdi-circle-slice-4', color: 'amber' },
    { value: 'purchase-order', label: 'Orden de Venta', description: 'El cliente ha aceptado la cotización, y estamos esperando la orden de venta.', icon: 'i-mdi-circle-slice-8', color: 'green' },
    { value: 'won', label: 'Ganado', description: 'El negocio ha sido cerrado exitosamente tras recibir la orden de ventadel cliente.', icon: 'i-mdi-trophy', color: 'green' },
    { value: 'lost', label: 'Perdido', description: 'El negocio se perdió por diversos motivos, y no avanzará más en el proceso.', icon: 'i-mdi-close-circle', color: 'red' },
  ],
  status: optionsSharedApp.statusProcess,
  priority: optionsSharedApp.priority,
  rating: [
    { value: '1', label: 'Frío', description: 'El cliente muestra poco interés en avanzar con la negociación.', icon: 'i-mdi-snowflake', color: 'blue' },
    { value: '2', label: 'Tibio', description: 'El cliente tiene un interés moderado, pero aún no está decidido.', icon: 'i-mdi-thermometer', color: 'amber' },
    { value: '3', label: 'Caliente', description: 'El cliente está altamente interesado y listo para avanzar.', icon: 'i-mdi-fire', color: 'red' },
  ],
} satisfies Options

export const relationsDeals: Record<string, boolean> = {
  account: true,
  activities: true,
  branch: true,
  campaign: true,
  inquiry: true,
  salesorders: true,
  quotes: true,
  user: true,
}
