import type { Options, Payment } from '#imports'
import { format } from 'date-fns'

export const defaultPayment: Payment = {
  satCfdiUse: '',
  satPaymentMethod: 'PUE',
  satPaymentForm: '03',
  conditions: 'NET0',
  currency: 'MXN',
  exchangeRate: {
    value: 1,
    date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  },
}

export const defaultLegalInformation = {
  taxId: 'EGR890713BK5',
  taxCountryCode: 'MX',
  legalName: 'ESPECIALIDADES DE GRAFITO',
  satFiscalRegime: '626',
  phone: '8183810381',
  email: 'ventas@esgraf.com.mx',
  address: {
    street: 'TRIANGULOS',
    numberExterior: '140',
    numberInterior: null,
    neighborhood: 'ARCO VIAL',
    city: 'GARCÍA',
    district: null,
    state: 'NUEVO LEON',
    country: 'MÉXICO',
    postalCode: '66023',
  },
}

export const defaultPdfSettings: PdfSettings = {
  watermark: {
    enabled: false,
    text: null,
    color: 'gray',
    opacity: 0.1,
    bold: true,
    italic: false,
    fontSize: 24,
    angle: 0,
  },
  password: {
    enabled: false,
    value: null,
  },
  images: true,
}

export const optionsSharedApp = {
  statusResource: [
    { value: 'active', label: 'Activo', description: 'En funcionamiento y disponible para su uso o interacción.', color: 'green', icon: 'i-mdi-check-circle' },
    { value: 'inactive', label: 'Inactivo', description: 'No está disponible en este momento o está desactivado temporalmente.', color: 'red', icon: 'i-mdi-close-circle' },
  ],
  statusProcess: [
    { value: 'pending', label: 'Pendiente', description: 'En espera de atención y aún no ha comenzado a procesarse.', icon: 'i-mdi-radiobox-blank', color: 'gray' },
    { value: 'in-progress', label: 'En Progreso', description: 'En proceso de atención, actualmente siendo ejecutado o trabajado.', icon: 'i-mdi-progress-clock', color: 'amber' },
    { value: 'waiting', label: 'En Espera', description: 'En pausa, esperando que se realice una acción o se resuelva algún detalle para continuar.', icon: 'i-mdi-hand-back-left', color: 'red' },
    { value: 'completed', label: 'Completado', description: 'Proceso finalizado con éxito, no requiere más acciones ni intervención.', icon: 'i-mdi-check-circle-outline', color: 'green' },
  ],
  priority: [
    { value: '1', label: 'Baja', description: 'Prioridad baja. Puede ser atendida en un despues sin generar problemas.', icon: 'i-mdi-arrow-down-circle', color: 'green' },
    { value: '2', label: 'Media', description: 'Prioridad media. Requiere atención en un tiempo razonable, pero no es urgente.', icon: 'i-mdi-arrow-right-circle', color: 'amber' },
    { value: '3', label: 'Alta', description: 'Prioridad alta. Necesita atención lo antes posible.', icon: 'i-mdi-arrow-up-circle', color: 'orange' },
    { value: '4', label: 'Crítica', description: 'Prioridad crítica. Requiere atención inmediata.', icon: 'i-mdi-alert', color: 'red' },
  ],
} satisfies Options
